<template>
    <div>
        <h6 class="page-title pb-2 text-uppercase">
            {{ $t("natural-person-builder.title") }}
        </h6>
        <b-row class="mb-3">
            <b-col md="6">
                <b-form-group>
                    <label class="form-title">
                        {{ $t("natural-person-builder.cedula") }}
                    </label>
                    <b-input-group>
                        <b-form-input @keyup.enter="callIdoneidadWS" v-model="naturalPerson.cedula" size="sm" class="rounded-0"
                            id="tooltip-target"></b-form-input>
                        <b-tooltip target="tooltip-target" triggers="hover">
                            Puede presionar <b>enter</b> para autocompletar los datos del Registro de Idoneidad
                        </b-tooltip>
                        <b-input-group-append>
                            <b-overlay
                                :show="loading"
                                rounded
                                opacity="0.7"
                                spinner-small
                                spinner-variant="primary"
                                class="d-inline-block"
                            >
                                <b-button
                                    size="sm"
                                    class="btn main-btn"
                                    @click="callIdoneidadWS"
                                    v-b-tooltip.hover
                                    title="Autocompletar los datos del Registro de Idoneidad"
                                >
                                    Validar
                                </b-button>
                            </b-overlay>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group>
                    <label class="form-title">
                        {{ $t("general.name") }}
                    </label>
                    <b-form-input v-model="naturalPerson.name" size="sm" class="rounded-0"
                        :disabled="naturalPerson.disabled == 1"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mb-3">
            <b-col md="6">
                <b-form-group>
                    <label class="form-title">
                        {{ $t("natural-person-builder.idoneidad") }}
                    </label>
                    <b-form-input v-model="naturalPerson.idoneidad" size="sm" class="rounded-0"
                        :disabled="naturalPerson.disabled == 1"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="6">
                <label class="form-title mt-4">
                    {{ labelResponse }}
                </label>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import axios from "axios";
import { mapActions } from "vuex";


const backendApiUrl = process.env.VUE_APP_BACKEND_API_URL;
const baseURL = `${backendApiUrl}api`;
export default {
    name: "natural-person-builder",
    props: {
        value: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            naturalPerson: {},
            loading: false,
            labelResponse: null
        };
    },
    mounted() {
        this.naturalPerson = this.value;
    },
    watch: {
        naturalPerson(naturalPerson) {
            this.$emit("input", naturalPerson);
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async callIdoneidadWS() {
            try {
                this.loading = true;
                this.labelResponse = null;

                if (this.naturalPerson.cedula) {
                    const URi = `${baseURL}/integration/idoneidad`;
                    var result = await axios.post(URi, { "cedula": this.naturalPerson.cedula })
                    if (result) {
                        if (result.data.existe == "1") {
                            this.naturalPerson.idoneidad = result.data.numero_idoneidad;
                            this.naturalPerson.name = result.data.nombre_completo;
                            this.naturalPerson.disabled = 1;
                            this.labelResponse = result.data.estado_idoneidad;
                        }
                        else {
                            this.naturalPerson.idoneidad = "";
                            this.naturalPerson.name = "";
                            this.naturalPerson.disabled = 0;
                            this.labelResponse = "NO IDONEO"
                        }
                    }
                }
            } catch (error) {
                console.log(error.response);                
                this.openModal();
                this.setModalInfo({
                    title: "Aviso",
                    message: "Error al consultar servicio",
                });
                return;
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>