<template>
    <div>
        <h6 class="page-title pb-2 text-uppercase">
            {{ $t("legal-person-builder.title") }}
        </h6>
        <b-row class="mb-3">
            <b-col md="6">
                <b-form-group>
                    <label class="form-title">
                        {{ $t("legal-person-builder.folio") }}
                    </label>
                    <b-form-input v-model="legalPerson.folio" size="sm" class="rounded-0"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group>
                    <label class="form-title">
                        {{ $t("general.name") }}
                    </label>
                    <b-form-input
                        v-model="legalPerson.name"
                        size="sm"
                        class="rounded-0"
                        :disabled="legalPerson.disabled == 1"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "legal-person-builder",
    props: {
        value: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            legalPerson: {}
        };
    },
    mounted() {
        this.legalPerson = this.value;
    },
    watch: {
        legalPerson(legalPerson) {
            this.$emit("input", legalPerson);
        }
    },
};
</script>