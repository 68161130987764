<template>
    <select
        class="form-select form-select-sm rounded-0"
        v-model="lawFirmType"
        @change="change"
    >
        <option :value="null"></option>
        <option
            v-for="lawFirmType in lawFirmTypes"
            :key="lawFirmType.key"
            :value="lawFirmType.key"
        >
            {{ $t(`law-firm-types.${lawFirmType.label}`) }}
        </option>
    </select>
</template>

<script>
import { lawFirmTypes } from "@/config/law-firm-types";

export default {
    name: "law-firm-type-select",
    props: {
        value: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            lawFirmType: null,
            lawFirmTypes: [],
        };
    },
    mounted() {
        this.lawFirmTypes = lawFirmTypes;
        this.lawFirmType = this.value;
    },
    methods: {
        change() {
            this.$emit("input", this.lawFirmType);
        },
    },
};
</script>