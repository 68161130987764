<!--
 --><template>
    <div class="mt-5 px-xl-5">
        <b-row class="mb-2 justify-content-center">
            <b-col cols="8">
                <h6 class="page-title pb-2 text-uppercase">
                    {{ $t("add-law-firm.title") }}
                </h6>
            </b-col>
        </b-row>
        <b-row class="mb-5 justify-content-center">
            <b-col cols="8">
                <b-card class="shadow-sm border-0 rounded-0 px-md-4" bg-variant="light" no-header>
                    <b-card-text>
                        <b-row class="pb-3">
                            <b-col md="6">
                                <b-form-group>
                                    <label class="form-title">
                                        {{ $t("general.law-firm-type") }}
                                    </label>
                                    <LawFirmTypeSelect v-model="registryForm.lawFirmType"
                                        @input="cleanType(registryForm)" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <LawFirmBuilder v-model="registryForm" />
                        <b-row class="mb-2">
                            <b-col class="text-end">
                                <b-overlay :show="sending" rounded opacity="0.7" spinner-small spinner-variant="primary"
                                    class="d-inline-block">
                                    <b-button class="main-btn rounded px-4" @click="registerLawFirm"
                                        :disabled="!canSave">{{ $t("general.save") }}</b-button>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions, mapGetters } from "vuex";

import LawFirmTypeSelect from "@/components/widgets/LawFirmTypeSelect.vue";
import LawFirmBuilder from "./LawFirmBuilder.vue";

export default {
    name: "add-law-firm",
    components: {
        LawFirmTypeSelect,
        LawFirmBuilder,
    },
    data() {
        return {
            registryForm: {
                name: "",
                folio: "",
                idoneidad: "",
                cedula: "",
                lawFirmType: null,
                societyType: "",
                disabled: 0,
            },
            sending: false,
        };
    },
    mounted() {
        if (!this.allowCreateRA) {
            this.$router.push({
                name: "law-firms",
            });
        }
    },
    computed: {
        ...mapGetters("application", ["allowSuperintendenceCreateRA"]),
        allowCreateRA() {
            return this.allowSuperintendenceCreateRA;
        },
        canSave() {
            let type = this.registryForm.lawFirmType;
            let form = this.registryForm;
            let valid = false;
            if (type === 0) {
                valid = form.name !== "" && form.folio !== "";
            } else if (type === 1) {
                valid =
                    form.name !== "" &&
                    form.idoneidad !== "" &&
                    form.cedula !== "";
            }
            return valid;
        },
        lawFirm() {
            let type = this.registryForm.lawFirmType;
            let form = this.registryForm;
            let lawFirm = {
                name: form.name,
                lawFirmType: type,
            };
            if (type === 0) {
                lawFirm.electronicFolio = form.folio;
                lawFirm.societyType = "sociedad anonima";
            } else if (type === 1) {
                lawFirm.electronicFolio = form.idoneidad;
                lawFirm.cedula = form.cedula;
            }
            return lawFirm;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async registerLawFirm() {
            this.sending = true;
            try {
                let request = {
                    lawFirm: this.lawFirm,
                };
                await RepositoryFactory.lawFirm.add(request);
                this.setSuccesInfo();
            } catch (error) {
                let errorCode = "";

                if (error.response && error.response.status === 400 && error.response.data) {
                    if (typeof (error.response.data) === "object") {
                        error.response.data.forEach(item => {
                            let msg = this.$t(`validation-exception.error_code.${item.value}`);
                            errorCode += `<p>${msg}</p>`;
                        });
                    }
                    else if (typeof (error.response.data) === "string") {
                        errorCode += `<p>${error.response.data}</p>`;
                    }
                }

                if (errorCode !== "") {
                    this.setModalInfo({
                        title: this.$t("add-law-firm.error.title"),
                        message: errorCode,
                    });
                }
                else {
                    this.setErrorInfo();
                }
            }
            this.openModal();
            this.sending = false;
        },
        setSuccesInfo(cur) {
            let message = "";
            let title = "";
            if (this.lawFirm.lawFirmType === 0) {
                message = this.$t("add-law-firm.success.message-legal").replace(
                    "{{cur}}",
                    cur
                );
                title = this.$t("add-law-firm.success.title-legal");
            } else {
                message = this.$t(
                    "add-law-firm.success.message-natural"
                ).replace("{{cur}}", cur);
                title = this.$t("add-law-firm.success.title-natural");
            }
            this.setModalInfo({
                title: title,
                message: message,
                routeName: "law-firms",
            });
        },
        setErrorInfo() {
            this.setModalInfo({
                title: this.$t("add-law-firm.error.title"),
                message: this.$t("add-law-firm.error.message"),
                subMessage: this.$t("add-law-firm.error.subMessage"),
            });
        },
        cleanType(registryForm) {
            registryForm.name = "";
            registryForm.cedula = "";
            registryForm.disabled = 0;
            registryForm.folio = "";
            registryForm.idoneidad = "";
            registryForm.societyType = "";
        }
    },
};
</script>