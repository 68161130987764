<template>
    <div>
        <component :is="lawFirmComponent" v-model="lawFirm" />
    </div>
</template>

<script>
import LegalPersonBuilder from "./law-firm-type/LegalPersonBuilder.vue";
import NaturalPersonBuilder from "./law-firm-type/NaturalPersonBuilder.vue";

export default {
    name: "law-firm-builder",
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            lawFirm: {},
        };
    },
    computed: {
        lawFirmComponent() {
            let type = this.lawFirm.lawFirmType;
            switch (type) {
                case 0:
                    return LegalPersonBuilder;
                case 1:
                    return NaturalPersonBuilder;
            }
            return null;
        },
    },
    mounted() {
        this.lawFirm = this.value;
    },
    watch: {
        lawFirm(lawFirm) {
            this.$emit("input", lawFirm);
        },
    },
};
</script>